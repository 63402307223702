import { Spacing, Theme } from '../../../css/baseStyles';

type BaseStyleProps = {
  theme: Theme;
  marginTop: number;
};
export const getBaseStyles = ({ theme, marginTop }: BaseStyleProps) => {
  return `
  <style id="RWMainStyle">
    * {
      box-sizing: border-box;
    }

    .hidden-image {
      display: none;
    }

    Body {
      margin: 0;
      width: 100%;
      position: relative;
      margin: auto;
    }

    .bottom-margin {
      height: ${Spacing.readerBottomPadding}px;
    }

    .overflow-hidden-enforcer {
      position: relative;
      overflow: hidden;
    }

    .failed-highlights-message-container {
      padding-left: 16px;
      padding-right: 16px;
    }

    .failed-highlights-message {
      color: ${theme.secondaryText};
      margin-bottom: 16px;
      font-family: var(--ui-font);
    }

    .failed-highlights-message button {
      display: block;
      margin-top: 0.75em;
      margin-left: 0;
      font-size: 14px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .image-before-load {
      height: 200px;
    }

    .document-container {
      width: 100%;
      position: relative;
      background-color: ${theme.readerBackground};
    }

    .rtl {
      direction: rtl;
      text-align: right;
    }

    .document-content {
      margin: auto;
      box-sizing: border-box;
      overflow: hidden;
      max-width: ${Spacing.maxDocumentWidth}px;
    }

    .document-content img {
      object-fit: contain;
    }

    .movable {
      transform: translateY(0px)!important;
    }

    .image-margin {
      top: 0;
      height: ${marginTop}px;
      z-index: -1;
      width: 100%;
      overflow: hidden;
    }

    .metadata svg {
      fill: ${theme.secondaryText};
      margin-right: 4px;
    }


    .search-highlighter {
      position: absolute;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: end;
      background: ${theme.searchHighlight};
      outline: 2px solid var( --focus-ring-color);
      border-radius: 4px;
      width: 1px;
      padding: 0 1px;
      left: -1px;
    }

    .search-highlighter-pop {
      animation: push 0.3s linear 1;
    }

    @keyframes push{
      50%  {transform: scale(1.2);}
    }

    #bottom-divider {
      height: 30px;
      border-top: 1px solid ${theme.borderTertiaryAlpha};
      background: ${theme.readerBackground};
    }

    .end-of-reading-container {
      margin: 0 auto;
      margin-bottom: 150px;
      display: block;
      padding-top: 38px;
      width: calc(100% - ${Spacing.baseHorizontalMargin} * 2);
      max-width: 375px;
      background-color: ${theme.readerBackground};
      padding-bottom: 24px;
      padding-left: ${Spacing.baseHorizontalMargin}px;
      padding-right: ${Spacing.baseHorizontalMargin}px;
      overflow: hidden;
    }

    #end-of-reading-button {
      fill: ${theme.buttonPrimaryDefaultColor}
    }

    #end-of-reading-text {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 17px;
      fill: ${theme.textInteractive};
    }

    /* End of reading keyframes */
    @keyframes a0_t { 0% { transform: translate(187.5px,36px) scale(0,0); animation-timing-function: cubic-bezier(0.372625,0,0.867035,0.778726); } 48.35% { transform: translate(187.5px,36px) scale(0.525,0.535555); animation-timing-function: cubic-bezier(0.645231,0.631791,1,1); } 50% { transform: translate(187.5px,36px) scale(0.603701,0.617328); animation-timing-function: cubic-bezier(0.804425,0.026502,0.58,1); } 53.35% { transform: translate(187.5px,36px) scale(1.003701,1.003113); } 58.35% { transform: translate(187.5px,36px) scale(1,1); } 100% { transform: translate(187.5px,36px) scale(1,1); } }
    @keyframes btn_t { 0% { transform: translate(-32px,-32px); } 50% { transform: translate(-32px,-32px); animation-timing-function: cubic-bezier(0.595,0,0.685,1.505); } 58.35% { transform: translate(-168px,-32px); } 100% { transform: translate(-168px,-32px); } }
    @keyframes btn_o { 0% { opacity: 0; } 39.15% { opacity: 0; } 50% { opacity: 1; } 58.35% { opacity: 1; } 100% { opacity: 1; } }
    @keyframes btn_w { 0% { width: 64px; } 50% { width: 64px; animation-timing-function: cubic-bezier(0.595,0,0.685,1.505); } 58.35% { width: 336px; } 100% { width: 336px; } }
    @keyframes text_t { 0% { transform: translate(187.5px,42.181877px) scale(0.8,0.8); } 51.65% { transform: translate(187.5px,42.181877px) scale(0.8,0.8); animation-timing-function: cubic-bezier(0.595,0,0.685,1.505); } 58.35% { transform: translate(187.5px,42.181877px) scale(1,1); } 100% { transform: translate(187.5px,42.181877px) scale(1,1); } }
    @keyframes text_o { 0% { opacity: 0; } 50% { opacity: 0; } 58.35% { opacity: 1; } 100% { opacity: 1; } }
    @keyframes checkmark_t { 0% { transform: translate(187.5px,36px) scale(2.6,2.6) translate(-16.0004px,-16.6004px); } 14.15% { transform: translate(187.5px,36px) scale(0.317,0.317) translate(-16.0004px,-16.6004px); } 50% { transform: translate(187.5px,36px) scale(1.6,1.6) translate(-16.0004px,-16.6004px); } 100% { transform: translate(187.5px,36px) scale(1.6,1.6) translate(-16.0004px,-16.6004px); } }
    @keyframes checkmark_o { 0% { opacity: 0; } 14.15% { opacity: 0; animation-timing-function: cubic-bezier(0.585,0,0.983145,0.469); } 50% { opacity: 1; animation-timing-function: steps(1); } 50.85% { opacity: 0; } 100% { opacity: 0; } }
    @keyframes check2_t { 0% { transform: translate(183.510605px,36px) rotate(0deg); } 45% { transform: translate(183.510605px,36px) rotate(0deg); } 48.35% { transform: translate(183.510604px,36.000002px) rotate(0deg); } 50% { transform: translate(187.511px,38.888527px) rotate(-44deg); } 100% { transform: translate(187.511px,38.888527px) rotate(-44deg); } }
    @keyframes check2_o { 0% { opacity: 1; } 50% { opacity: 1; } 50.85% { opacity: 0; } 100% { opacity: 0; } }
    @keyframes check2_sw { 0% { stroke-width: 0px; } 48.35% { stroke-width: 2px; } 100% { stroke-width: 2px; } }
    @keyframes check1_t { 0% { transform: translate(183.510605px,36px) rotate(0deg) translateX(0.010605px); } 45% { transform: translate(183.510605px,36px) rotate(0deg) translateX(0.010605px); } 48.35% { transform: translate(183.510604px,36.000002px) rotate(0deg) translateX(0.010605px); } 50% { transform: translate(183.334058px,39.282374px) rotate(43deg) translateX(0.010605px); } 100% { transform: translate(183.334058px,39.282374px) rotate(43deg) translateX(0.010605px); } }
    @keyframes check1_o { 0% { opacity: 1; } 50% { opacity: 1; } 50.85% { opacity: 0; } 100% { opacity: 0; } }
    @keyframes check1_sw { 0% { stroke-width: 0px; } 48.35% { stroke-width: 2px; } 100% { stroke-width: 2px; } }
    @keyframes line_sw { 0% { stroke-width: 0px; } 48.35% { stroke-width: 2px; } 100% { stroke-width: 2px; } }

    .youtube-current-teleprompter {
      background: ${theme.teleprompt};
      color: white;
    }

  </style>
  `;
};
