/* eslint-disable no-console */

import { defaultLogLevel, LogLevelToRank, LogMethodName, LogMethodToLogLevel } from '../types/logging';
import { isDeployPreview, isDevOrTest, isMobile, logLevelPreference } from './environment';

type LogExtra = { [name: string]: unknown };
export type LogFn = (message: string, extra?: LogExtra) => void;
type ShouldLogFn = (
  methodName: LogMethodName,
  module: string,
  message: Parameters<LogFn>[0],
  extra: Parameters<LogFn>[1],
) => boolean;

export type LoggerOptions = {
  shouldLog?: ShouldLogFn | boolean;
  shouldPrintLogsInProduction?: boolean;
};

const logLevelToRank = (logLevel: string | undefined): number =>
  LogLevelToRank[logLevel ?? defaultLogLevel] ?? LogLevelToRank[defaultLogLevel];

export class ReadwiseLogger {
  filenameOrName: string;
  module: string;
  shouldLog?: ShouldLogFn;
  shouldPrintLogsInProduction: boolean;

  constructor(filenameOrName: string, options: LoggerOptions = {}) {
    this.filenameOrName = filenameOrName;
    this.module = this._parseModuleFromFilename(filenameOrName);
    const { shouldLog } = options;
    if (typeof shouldLog === 'boolean') {
      this.shouldLog = () => Boolean(shouldLog);
    } else {
      this.shouldLog = shouldLog;
    }
    this.shouldPrintLogsInProduction = options.shouldPrintLogsInProduction ?? false;
  }

  get prefix() {
    return `[${this.module}]`;
  }

  [LogMethodName.Debug](...args: Parameters<LogFn>) {
    if (!(this instanceof ReadwiseLogger)) {
      throw new Error('this is not an instanceof ReadwiseLogger');
    }
    this._log(LogMethodName.Debug, console.debug, ...args);
  }

  [LogMethodName.Info](...args: Parameters<LogFn>) {
    if (!(this instanceof ReadwiseLogger)) {
      throw new Error('this is not an instanceof ReadwiseLogger');
    }
    this._log(LogMethodName.Info, console.info, ...args);
  }

  [LogMethodName.Error](...args: Parameters<LogFn>) {
    if (!(this instanceof ReadwiseLogger)) {
      throw new Error('this is not an instanceof ReadwiseLogger');
    }
    this._log(LogMethodName.Error, console.error, ...args);
  }

  [LogMethodName.Log](...args: Parameters<typeof console.log>) {
    if (!(this instanceof ReadwiseLogger)) {
      throw new Error('this is not an instanceof ReadwiseLogger');
    }
    if (this.shouldLog) {
      if (!this.shouldLog(LogMethodName.Log, this.module, '', undefined)) {
        return;
      }
    } else {
      const logLevel = LogMethodToLogLevel[LogMethodName.Log];
      if (logLevelToRank(logLevel) < logLevelToRank(logLevelPreference)) {
        return;
      }
    }
    console.log(...args);
  }

  [LogMethodName.Time](message: string) {
    if (!(this instanceof ReadwiseLogger)) {
      throw new Error('this is not an instanceof ReadwiseLogger');
    }
    this._log(LogMethodName.Time, (message) => console.time(`${message}`), message);
  }

  [LogMethodName.TimeEnd](message: string) {
    if (!(this instanceof ReadwiseLogger)) {
      throw new Error('this is not an instanceof ReadwiseLogger');
    }
    this._log(LogMethodName.TimeEnd, (message) => console.timeEnd(`${message}`), message);
  }

  [LogMethodName.Warn](...args: Parameters<LogFn>) {
    if (!(this instanceof ReadwiseLogger)) {
      throw new Error('this is not an instanceof ReadwiseLogger');
    }
    this._log(LogMethodName.Warn, console.warn, ...args);
  }

  private _parseModuleFromFilename(filenameOrName: string): string {
    let module = filenameOrName;

    // Ends with */*.*
    if (/.+\/.+.+\..+$/.test(module)) {
      module = module.split('/').pop() || '';
      if (['index.', 'methods.', 'models.'].some((prefix) => module.startsWith(prefix))) {
        // "index.ts" is not a very descriptive module name
        module = filenameOrName.split('/').slice(-2).join('/'); // .. so we make it e.g. background/index.ts
      }
      if (module.includes('.')) {
        module = module.split('.').slice(0, -1).join('.'); // directory/module.ts -> directory/module
      }
    }

    return module;
  }

  private _log(
    methodName: LogMethodName,
    logImpl: (message: string, extra?: LogExtra) => void,
    message: string,
    extra?: LogExtra,
  ): void {
    if (!this.shouldPrintLogsInProduction && !isDevOrTest && !isDeployPreview) {
      return;
    }
    if (this.shouldLog) {
      if (!this.shouldLog(methodName, this.module, message, extra)) {
        return;
      }
    } else {
      const logLevel = LogMethodToLogLevel[methodName];
      if (logLevelToRank(logLevel) < logLevelToRank(logLevelPreference)) {
        return;
      }
    }

    if (extra) {
      if (isMobile) {
        try {
          // Mobile console doesn't print large objects nicely.
          for (const key of Object.keys(extra)) {
            const value = extra[key];
            if (value instanceof Array && JSON.stringify(value).length >= 1000) {
              extra[key] = value.length;
            }
          }
          const safeExtra = Object.entries(extra)
            .map(([key, value]) => `${key}=${JSON.stringify(value)}`)
            .join(', ');
          logImpl(`${this.prefix} ${message}: ${safeExtra}`);
        } catch (e) {
          // ignore
          logImpl(`${this.prefix} ${message}`, extra);
        }
      } else {
        logImpl(`${this.prefix} ${message}`, extra);
      }
    } else {
      logImpl(`${this.prefix} ${message}`);
    }
  }
}

/**
 * Create a logger.
 *
 * @param filenameOrName Name that is prefixed to every log message. Pass `__filename` unless you want a custom name like `'database'`.
 * @param options
 */
export default function makeLogger(filenameOrName: string, options: LoggerOptions = {}): ReadwiseLogger {
  return new ReadwiseLogger(filenameOrName, options);
}
