import type Database from '../../../database/Database';
import {
  type DocumentId,
  type MinimalPersistentState,
  type PersistentState,
  type PersistentStateWithDocuments,
  type PersistentUpdate,
  PersistentStateLoadingState,
} from '../../../types';
import attachRxDBDocumentsToPersistentState from '../../../utils/attachRxDBDocumentsToPersistentState';
import makeLogger from '../../../utils/makeLogger';
// eslint-disable-next-line import/no-cycle
import database from '../../database';
import { CancelStateUpdate, globalState, updatePropertyInState, updateState } from '../../models';
// eslint-disable-next-line import/no-cycle
import listenToDocumentLocationsAndFixClientState from '../../utils/listenToDocumentLocationsAndFixClientState.platform';
import updateAllStateUsingJsonPatchOperations from '../../utils/updateAllStateUsingJsonPatchOperations';
import { fetchDocumentContent } from '../transientStateUpdaters/documentContent';

const logger = makeLogger(__filename);

export const setCurrentPersistentStateLoadingState = async (
  persistentLoadingState: PersistentStateLoadingState,
) => {
  await updatePropertyInState('persistentStateLoadingState', persistentLoadingState, {
    eventName: 'persistent-state-loading-state-updated',
    isUndoable: false,
    userInteraction: null,
  });
};

export const setPersistentStateTotalDocumentsToAddCount = (documentCount: string) => {
  updatePropertyInState('persistentStateTotalDocumentsToAddCount', documentCount, {
    eventName: 'persistent-state-loading-state-total-document-count-updated',
    isUndoable: false,
    userInteraction: null,
  });
};

export const incrementPersistentStateLoadedDocumentCountByN = (n: number) => {
  updateState(
    (state) => {
      state.persistentStateNumberOfDocumentsAdded += n;
    },
    {
      eventName: 'persistent-state-loading-state-total-document-count-updated',
      isUndoable: false,
      userInteraction: null,
    },
  );
};

export const setAreServerUpdatesBeingAppliedToForeground = (changesAreBeingApplied: boolean) => {
  updateState(
    (state) => {
      if (state.areServerUpdatesBeingAppliedToForeground === changesAreBeingApplied) {
        throw new CancelStateUpdate();
      }
      state.areServerUpdatesBeingAppliedToForeground = changesAreBeingApplied;
    },
    {
      eventName: 'are-server-updates-being-applied-to-foreground-updated',
      isUndoable: false,
      userInteraction: null,
    },
  );
};

export const setAreServerUpdatesBeingFetchedByUser = (areUpdatesBeingFetched: boolean) => {
  updateState(
    (state) => {
      if (state.areServerUpdatesBeingFetchedByUser === areUpdatesBeingFetched) {
        throw new CancelStateUpdate();
      }
      state.areServerUpdatesBeingFetchedByUser = areUpdatesBeingFetched;
    },
    {
      eventName: 'are-server-updates-being-fetched-updated',
      isUndoable: false,
      userInteraction: null,
    },
  );
};

export const initPersistentState = async (
  persistentState: PersistentState | MinimalPersistentState,
): Promise<void> => {
  listenToDocumentLocationsAndFixClientState();

  await updateState(
    (state) => {
      state.persistent = persistentState;
      state.persistentStateLoaded = true;
      state.persistentStateLoadingState = PersistentStateLoadingState.Done;
    },
    {
      eventName: 'persistent-state-initialized',
      shouldNotSendPersistentChangesToServer: true,
      isUndoable: false,
      userInteraction: null,
    },
  );
};

function extractDocumentIdFromJsonPatchPath(path: string) {
  const regex = /\/documents\/([a-zA-Z0-9]+)/;
  const match = path.match(regex);
  return match ? match[1] : null;
}

export const onBackgroundStateUpdates = async (updates: PersistentUpdate[]): Promise<void> => {
  logger.debug('Background to foreground state updates: ', { updates });
  const operations = [];
  for (const update of updates) {
    for (const patch of update.patch) {
      const didReparsedAtChange =
        (patch.op === 'add' || patch.op === 'replace') &&
        /^\/documents\/[a-zA-Z0-9]+\/source_specific_data\/reparsed_at$/.test(patch.path);

      if (didReparsedAtChange) {
        const docId = extractDocumentIdFromJsonPatchPath(patch.path);
        if (docId) {
          fetchDocumentContent([docId], true);
        }
      }

      operations.push(patch);
    }
  }
  await updateAllStateUsingJsonPatchOperations({
    database,
    canModifyOperations: true,
    operations,
    eventName: 'background-updates',
    shouldNotSendPersistentChangesToServer: true,
    userInteraction: null,
    isUndoable: false,
    updateState,
  });
};

// Should only used by the background for the sake of syncing -- not by the foreground for any app logic.
export const getCurrentPersistentStateWithDocuments = async (
  database: Database,
  filterDocumentIds?: DocumentId[],
): Promise<PersistentStateWithDocuments> => {
  const persistentState: PersistentState = globalState.getState().persistent;

  return attachRxDBDocumentsToPersistentState(database, persistentState, filterDocumentIds);
};

export const runForegroundStateChecksum = (expectedChecksum: number) => {
  return true;
  // TODO: uncomment when we re-enable checksums
  // const documents = keyBy(
  //   await database.collections.documents.findAll(),
  //   'id',
  // );
  // return runUserDocumentsChecksum(documents, expectedChecksum);
};
