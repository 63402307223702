/* eslint-disable no-underscore-dangle, no-console */
import { EventEmitter2 } from 'eventemitter2';
import pick from 'lodash/pick';

import createError from './utils/createError';
import { DeferredPromise } from './utils/DeferredPromise';
import { isExtension, isMobile } from './utils/environment';

type ConstructorParams = {
  getGateName: () => string | Promise<string>;
  localMethods?: MethodsObject;
  loggingColor: string;
  loggingPrefix: string;
  portalName: string;
  sendMessage: ((arg: CallPayload, payloadCategory: 'call') => Promise<unknown>) &
    ((arg: CallResponsePayload, payloadCategory: 'callResponse') => Promise<unknown>) &
    ((arg: EventPayload, payloadCategory: 'event') => Promise<unknown>);

  /*
    Default:

    {
      errors: false,
      events: false,
      methods: false,
    }

    Why aren't errors logged by default? A method might throw an error, but maybe that's ok. The caller
    might have a try-catch and ignore it or do something else. We can't assume an error is a real problem
    that should be logged. As always, calling a method should act like a plain JS function call.
  */
  shouldLog?: {
    errors: boolean;
    events: boolean;
    methods: boolean;
  };
};
export type CallPayload = {
  data: {
    args: unknown[];
    correlationId: string;
    initiatorGateName: string;
    isEvent: boolean;
    isResponse: boolean;
    propertyName: string;
    source: string;
  };
  message: string;
};
export type CallResponsePayload = {
  data: Pick<
    CallPayload['data'],
    'correlationId' | 'initiatorGateName' | 'isEvent' | 'isResponse' | 'source'
  > & {
    error?: Error;
    result?: unknown;
  };
  message: CallPayload['message'];
};
type EventPayload = {
  data: Pick<CallPayload['data'], 'isEvent' | 'source'> & {
    argument: unknown;
    eventName: string;
  };
  message: CallPayload['message'];
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MethodArgs = any[];
type MethodsObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [propertyName: string]: (...args: MethodArgs) => Promise<any> | any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EventNameToCallbackArgumentsMap = { [a: string]: any };

export default class PortalGate<
  TTargetMethods extends MethodsObject,
  TEventNamesAndArguments extends EventNameToCallbackArgumentsMap = EventNameToCallbackArgumentsMap,
> {
  getGateName: ConstructorParams['getGateName'];
  methods: TTargetMethods;
  portalName: ConstructorParams['portalName'];

  _eventEmitter: EventEmitter2;
  _localMethods: ConstructorParams['localMethods'];
  _loggingColor: ConstructorParams['loggingColor'];
  _loggingPrefix: ConstructorParams['loggingPrefix'];
  _messagePrefix: string;
  _ongoingCalls: {
    [correlationId: string]: DeferredPromise<CallResponsePayload['data']['result']>;
  };

  _sendMessage: ConstructorParams['sendMessage'];
  _shouldLog: NonNullable<ConstructorParams['shouldLog']>;

  constructor({
    getGateName,
    localMethods,
    loggingColor,
    loggingPrefix,
    portalName,
    sendMessage,
    shouldLog = {
      errors: false,
      events: false,
      methods: false,
    },
  }: ConstructorParams) {
    this.getGateName = getGateName;
    this.portalName = portalName;

    /* Private */
    this._eventEmitter = new EventEmitter2();
    this._localMethods = localMethods;
    this._loggingColor = loggingColor;
    this._loggingPrefix = loggingPrefix;
    this._messagePrefix = `portal-${portalName}__`;
    this._ongoingCalls = {};
    this._sendMessage = sendMessage;
    this._shouldLog = shouldLog;

    this.methods = this._createMethodProxy({
      messagePrefix: this._messagePrefix,
      sendMessage,
    }) as TTargetMethods;
  }

  async emit<T extends keyof TEventNamesAndArguments>(
    name: T,
    argument?: TEventNamesAndArguments[T] | never,
  ): Promise<void> {
    this._log('events', `☎ Emitting event "${String(name)}"`, argument);
    await this._sendMessage(
      {
        data: {
          argument,
          eventName: name,
          isEvent: true,
          source: await this.getGateName(),
        },
        message: `${this._messagePrefix}event__${String(name)}`,
      } as EventPayload,
      'event',
    );
  }

  /*
    Returns a Boolean; true if the message is related to this portal and is handled.
  */
  async handleIncomingMessage(
    payload: CallPayload | CallResponsePayload | EventPayload,
  ): Promise<boolean> {
    if (
      !payload.message?.startsWith(this._messagePrefix) ||
      payload.data.source === (await this.getGateName())
    ) {
      return false;
    }

    if (payload.data.isEvent) {
      await this._handleIncomingEvent(payload as EventPayload);
      return true;
    }

    if ((payload as CallPayload | CallResponsePayload).data.isResponse) {
      await this._handleIncomingResponse(payload as CallResponsePayload);
    } else {
      await this._handleIncomingCall(payload as CallPayload);
    }

    return true;
  }

  off<T extends keyof TEventNamesAndArguments>(
    eventName: T,
    callback: (args: TEventNamesAndArguments[T]) => void,
  ): void {
    this._eventEmitter.off(eventName as string, callback);
  }

  on<T extends keyof TEventNamesAndArguments>(
    eventName: T,
    callback: (args: TEventNamesAndArguments[T]) => void,
    options?: Parameters<EventEmitter2['on']>[2],
  ): ReturnType<EventEmitter2['on']> {
    return this._eventEmitter.on(eventName as string, callback, options);
  }

  once<T extends keyof TEventNamesAndArguments>(
    eventName: T,
    callback: (args: TEventNamesAndArguments[T]) => void,
    options?: Parameters<EventEmitter2['once']>[2],
  ): ReturnType<EventEmitter2['once']> {
    return this._eventEmitter.once(eventName as string, callback, options);
  }

  waitFor<T extends keyof TEventNamesAndArguments>(
    eventName: T,
    timeout?: Parameters<EventEmitter2['waitFor']>[1],
  ): ReturnType<EventEmitter2['waitFor']> {
    return this._eventEmitter.waitFor(eventName as string, timeout);
  }

  /*
    We use a Proxy to intercept any this.methods.* call and perform the call via message passing
  */
  _createMethodProxy({
    messagePrefix,
    sendMessage,
  }: {
    messagePrefix: string;
    sendMessage: ConstructorParams['sendMessage'];
  }): TTargetMethods {
    return new Proxy(
      {},
      {
        get:
          (target, propertyName: string) =>
          async (...args: MethodArgs) => {
            if (!sendMessage) {
              throw new Error("sendMessage wasn't given when creating gate");
            }

            const correlationId = Math.random().toString();

            const gateName = await this.getGateName();

            const data = {
              args,
              correlationId,
              initiatorGateName: gateName,
              isEvent: false,
              isResponse: false,
              propertyName,
              source: gateName,
            };

            this._log('methods', `☎ Calling "${data.propertyName}"`, data);

            this._ongoingCalls[correlationId] = new DeferredPromise<
              CallResponsePayload['data']['result']
            >();

            let error: CallResponsePayload['data']['error'];
            let result: CallResponsePayload['data']['result'];

            try {
              result = await sendMessage(
                {
                  data,
                  message: `${messagePrefix}${propertyName.toString()}`,
                },
                'call',
              );

              // Some messengers allow for returning a result in the callback. If that's not the case...
              if (!result) {
                result = await this._ongoingCalls[correlationId];
              }
            } catch (err) {
              error = err as Error;
            }

            this._log('methods', `☑ Received result from "${propertyName}"`, { error, result });

            delete this._ongoingCalls[correlationId];

            if (error) {
              throw error;
            }

            return result;
          },
      },
    ) as TTargetMethods;
  }

  async _handleIncomingEvent({ data }: EventPayload): Promise<void> {
    this._log('events', `📞 Received event "${data.eventName}"`);
    await this._eventEmitter.emitAsync(data.eventName, data.argument);
  }

  async _handleIncomingCall({ data, message }: CallPayload): Promise<void> {
    if (!this._localMethods) {
      throw new Error('No localMethods given when creating gate');
    }
    this._log('methods', `📞 Received call to "${data.propertyName}"`);

    if (data.args.some((arg) => typeof arg === 'function')) {
      throw new Error(
        'Function arguments are not allowed. More info: https://github.com/readwiseio/rekindled/blob/master/reading-clients/reader/README.md#rules',
      );
    }

    let error: CallResponsePayload['data']['error'];
    let result: CallResponsePayload['data']['result'];
    try {
      result = await this._localMethods[data.propertyName](...data.args);
    } catch (err) {
      if (this._shouldLog.errors) {
        console.error(err);
      }
      if (err instanceof Error) {
        error = createError(
          pick(err, [
            'cause',
            'columnNumber',
            'fileName',
            'lineNumber',
            'message',
            'name',
            'response',
            'stack',
          ]),
        );
      } else {
        // let's pass on whatever it is
        error = createError(err);
      }
    }
    const responseData = {
      correlationId: data.correlationId,
      // parse out just the values we need (this can only include objects, arrays, strings, numbers, null, or undefined)
      error: error
        ? pick(error, [
            'cause',
            'columnNumber',
            'fileName',
            'lineNumber',
            'message',
            'name',
            'stack',
            'response',
          ])
        : undefined,
      initiatorGateName: data.source,
      isEvent: false,
      isResponse: true,
      result,
      source: await this.getGateName(),
    };
    this._log('methods', `⬅ Returning result from "${data.propertyName}"`);
    await this._sendMessage(
      {
        data: responseData,
        message: `${message}__RESPONSE`,
      } as CallResponsePayload,
      'callResponse',
    );
  }

  async _handleIncomingResponse({ data, message }: CallResponsePayload): Promise<void> {
    if (data.correlationId in this._ongoingCalls) {
      if (data.error) {
        this._ongoingCalls[data.correlationId].reject(createError(data.error));
      } else {
        this._ongoingCalls[data.correlationId].resolve(data.result);
      }
    } else {
      const errorMessage = 'Cannot find ongoing call by the correlation ID given';
      this._log('methods', `‼ ${errorMessage}`, {
        ...pick(this, ['_ongoingCalls', 'portalName']),
        data,
        message,
      });
      if (isExtension) {
        return;
      }
      throw new Error(errorMessage);
    }
  }

  async _log(kind: 'events' | 'methods', ...logArgs: unknown[]): Promise<void> {
    if (!this._shouldLog[kind]) {
      return;
    }

    const hasColorSupport = !isMobile;
    const colorMarker = hasColorSupport ? '%c' : '';

    const consoleArgs = [`${this._loggingPrefix}${colorMarker}[${await this.getGateName()}]`];
    if (hasColorSupport) {
      consoleArgs.push(`color: ${this._loggingColor}`);
    }
    consoleArgs.push(...(logArgs as string[]));

    console.log(...consoleArgs);
  }
}
