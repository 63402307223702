import type { PointerLikeEvent } from '../../../types/browserEvents';
import makeLogger from '../../../utils/makeLogger';
import areRectsEqual from '../../utils/areRectsEqual';
import classListSafe from '../../utils/classListSafe';
import getClosestHTMLElement from '../../utils/getClosestHTMLElement';
import makeIntoNonOverlappingRects from '../../utils/makeIntoNonOverlappingRects';
import Renderer from '../../utils/Renderer';
import * as autoScroller from '../autoScroller';
// eslint-disable-next-line import/no-cycle
import { getRendererOrThrow } from '../index';

/*
  NOTE: this directory is intended to be called from within the content frame, not outside
*/

const logger = makeLogger(__filename);

// Paused means the currently rendered selection is kept as is but we stop reacting to events
let selectionEmulationStatus: 'active' | 'inactive' | 'paused' = 'inactive';
const selectionEmulationContainerId = 'selection-emulation';

export function onSelectionChange() {
  const selection = getSelection();
  logger.debug('onSelectionChange', {
    selectionEmulationStatus,
    selectionRange: selection?.rangeCount && selection.getRangeAt(0),
  });
  if (selectionEmulationStatus !== 'active') {
    return;
  }

  let selectionContainer = document.getElementById(selectionEmulationContainerId);
  const onExitEarly = () => {
    selectionContainer?.remove();
  };

  if (
    !selection?.rangeCount ||
    !selection
      .toString()
      .replace(/\u2060/g, '')
      .trim()
  ) {
    onExitEarly();
    return;
  }

  const range = selection.getRangeAt(0);
  if (!range) {
    onExitEarly();
    return;
  }

  /*
    On iOS, if the edge being resized was on the handle, a big selection square was (later) drawn, so
    let's move the selection inside past the handle
  */
  const startContainerElement = getClosestHTMLElement(range.startContainer);
  if (startContainerElement?.closest(`.${Renderer.highlightResizeHandleClassName}`)) {
    range.setStartAfter(startContainerElement);
  }
  const endContainerElement = getClosestHTMLElement(range.endContainer);
  if (endContainerElement?.closest(`.${Renderer.highlightResizeHandleClassName}`)) {
    range.setEndBefore(endContainerElement);
  }

  const containerNode = getRendererOrThrow().containerNode;
  if (!containerNode.contains(range.commonAncestorContainer)) {
    onExitEarly();
    return;
  }

  if (!selectionContainer) {
    selectionContainer = document.createElement('div');
    selectionContainer.id = selectionEmulationContainerId;
    containerNode.appendChild(selectionContainer);
  }

  const containerRect = containerNode.getBoundingClientRect();

  // The rects we want to draw
  const selectionRects = makeIntoNonOverlappingRects(
    Array.from(range.getClientRects()).filter((rect) => rect.width >= 1 || rect.height >= 1),
  );

  // Preserve the end which isn't changing, to avoid unnecessary flickering
  let indexOfFirstSelectionContainerChildToKeep = 0;
  let indexOfLastSelectionContainerChildToKeep = selectionRects.length - 1;
  const previousDrawnRects = Array.from(selectionContainer.children, (child) =>
    child.getBoundingClientRect(),
  );
  if (
    previousDrawnRects &&
    areRectsEqual(
      previousDrawnRects[previousDrawnRects.length - 1],
      selectionRects[selectionRects.length - 1],
    )
  ) {
    indexOfLastSelectionContainerChildToKeep = previousDrawnRects.length - 1;
    indexOfFirstSelectionContainerChildToKeep = Math.max(
      0,
      indexOfLastSelectionContainerChildToKeep - selectionRects.length + 1,
    );
  }

  // Remove children from last time that we don't need anymore
  Array.from(selectionContainer.children).forEach((child, index) => {
    if (
      index < indexOfFirstSelectionContainerChildToKeep ||
      index > indexOfLastSelectionContainerChildToKeep
    ) {
      child.remove();
    }
  });

  // Draw rects with elements
  for (let i = 0; i < selectionRects.length; i++) {
    const rect = selectionRects[i];

    let selectionContainerChild = selectionContainer.children[i] as HTMLElement;
    if (!selectionContainerChild) {
      selectionContainerChild = document.createElement('span');
      selectionContainer.appendChild(selectionContainerChild);
    }

    for (const propertyName of ['top', 'right', 'bottom', 'left', 'height', 'width']) {
      let value = rect[propertyName];
      if (['top', 'left', 'right', 'bottom'].includes(propertyName)) {
        value = value - containerRect[propertyName];
      }
      selectionContainerChild.style[propertyName] = `${value}px`;
    }
  }
}

export function pauseEmulatingSelection() {
  logger.debug('pauseEmulatingSelection', { selectionEmulationStatus });
  if (selectionEmulationStatus !== 'active') {
    return;
  }
  autoScroller.stopListening();
  selectionEmulationStatus = 'paused';
}

export function startEmulatingSelection(lastPointerDownEvent: PointerLikeEvent) {
  logger.debug('startEmulatingSelection', { lastPointerDownEvent, selectionEmulationStatus });
  if (selectionEmulationStatus === 'active') {
    return;
  }

  if (!lastPointerDownEvent.target) {
    logger.warn('lastPointerDownEvent.target does not exist');
    return;
  }

  selectionEmulationStatus = 'active';
  const containerNode = getRendererOrThrow().containerNode;
  classListSafe.add(containerNode, 'is-emulating-selection');
  onSelectionChange(); // Draw current selection
  document.addEventListener('selectionchange', onSelectionChange);
  autoScroller.startListening(lastPointerDownEvent);
}

export function stopEmulatingSelection() {
  logger.debug('stopEmulatingSelection', { selectionEmulationStatus });
  if (selectionEmulationStatus === 'inactive') {
    return;
  }
  autoScroller.stopListening();
  selectionEmulationStatus = 'inactive';
  document.removeEventListener('selectionchange', onSelectionChange);
  classListSafe.remove(getRendererOrThrow().containerNode, 'is-emulating-selection');
  document.getElementById(selectionEmulationContainerId)?.remove();
}
