import type { HighlightResizeState } from '../../types/highlights';
import { isTextNode } from '../../typeValidators';
import type { RangySelection } from '../types/rangy';
import contractRangeIfPossible from './contractRangeIfPossible';
import getOppositeEnd from './getOppositeEnd';
import getSiblings from './getSiblings';
import isImage from './isImage';
import setSingleRangeInRangySelection from './setSingleRangeInRangySelection';

const PUNCTUATION_REGEX = /[!"$%'().;?[\]`{}“”‘’]/;
// This is not an exhaustive list, feel free to extend this list if missing something
const INLINE_TEXT_TAGS = ['B', 'EM', 'A', 'SPAN', 'STRONG', 'I', 'U', 'RW-HIGHLIGHT'];

const countWhitespace = (text: string) => (text.match(/\s+/g) ?? []).length;

function canModifyEnd(
  end: 'end' | 'start',
  getHighlightResizeState: () => HighlightResizeState,
  isSelectionBackwards: boolean,
) {
  const highlightResizeState = getHighlightResizeState();

  if (highlightResizeState.status === 'native-selection-made-but-user-hasnt-started-resizing-yet') {
    // Just the highlight should be selected
    return false;
  }

  // When a resize is happening, the end not being moved should stay where it is
  if (
    ['actively-resizing', 'user-interaction-done-waiting-for-render'].includes(
      highlightResizeState.status,
    ) &&
    highlightResizeState.edgeResizeStartedFrom
  ) {
    /*
      If resizing from the start, the selection is expected to be backwards (i.e. focus node & offset is
      before anchor nodde & offset)
    */
    const hasSelectionBeenFlippedDuringResize =
      highlightResizeState.edgeResizeStartedFrom === 'start'
        ? !isSelectionBackwards
        : isSelectionBackwards;

    const endOfSelectionResizeStartedFrom = hasSelectionBeenFlippedDuringResize
      ? getOppositeEnd(highlightResizeState.edgeResizeStartedFrom)
      : highlightResizeState.edgeResizeStartedFrom;

    if (getOppositeEnd(endOfSelectionResizeStartedFrom) === end) {
      return false;
    }
  }

  return true;
}

export function grabPunctuationAtStart(
  rangySelection: RangySelection,
  getHighlightResizeState: () => HighlightResizeState,
) {
  if (
    !canModifyEnd('start', getHighlightResizeState, rangySelection.isBackwards()) ||
    !rangySelection.rangeCount ||
    rangySelection.isCollapsed
  ) {
    return;
  }

  // If this selection is just one or two words, dont grab punctuation;
  if (countWhitespace(rangySelection.toString()) < 2) {
    return;
  }

  const rangyRange = rangySelection.getRangeAt(0);
  if (
    isImage(rangyRange.startContainer) ||
    isImage(rangyRange.startContainer.childNodes[rangyRange.startOffset]) ||
    isImage(rangyRange.startContainer.childNodes[rangyRange.startOffset + 1])
  ) {
    return;
  }

  const firstNodeInSelection = rangyRange.startContainer;
  const offsetInNode = rangyRange.startOffset;
  if (
    !firstNodeInSelection ||
    !firstNodeInSelection.textContent ||
    (firstNodeInSelection.parentElement &&
      firstNodeInSelection.parentElement.nodeName === 'RW-HIGHLIGHT')
  ) {
    // return if no text or its already a highlight we are resizing
    return;
  }

  /*
    IF we are at the start of the node
    IF we have a parent element and its an inline tag
    IF the parent element is not a highlight already (we are not resizing)
   */
  if (
    offsetInNode === 0 &&
    firstNodeInSelection.parentElement &&
    INLINE_TEXT_TAGS.includes(firstNodeInSelection.parentElement.nodeName ?? '')
  ) {
    const { siblings: previousTextNodes } = getSiblings({
      direction: 'previous',
      element: firstNodeInSelection.parentElement,
      matcher: isTextNode,
      shouldIncludeNonElements: true,
    });
    /*
      if we have a previous text node select the node at the very end of its text
      If it ends on a punctuation, this code will then grab it recursively
     */
    let lastNode = null;
    if (previousTextNodes.length > 0) {
      lastNode = previousTextNodes[0];
      // Don't call this if the next character is whitespace
      if (lastNode.textContent && lastNode.textContent[lastNode.textContent.length - 1] !== ' ') {
        rangyRange.setStart(lastNode, lastNode.textContent.length - 1);
        setSingleRangeInRangySelection(rangyRange, rangySelection, 'maintain-current-direction');
        return grabPunctuationAtStart(rangySelection, getHighlightResizeState);
      }
    }
  }

  let currentOffset = offsetInNode;
  while (currentOffset > 0) {
    const charToTest = firstNodeInSelection.textContent.slice(currentOffset - 1, currentOffset);
    if (!PUNCTUATION_REGEX.test(charToTest)) {
      break;
    }
    currentOffset -= 1;
  }
  if (currentOffset === offsetInNode) {
    return;
  }

  rangyRange.setStart(firstNodeInSelection, Math.max(currentOffset, 0));
  setSingleRangeInRangySelection(rangyRange, rangySelection, 'maintain-current-direction');
}

export function grabPunctuationAtEnd(
  rangySelection: RangySelection,
  getHighlightResizeState: () => HighlightResizeState,
) {
  if (
    !canModifyEnd('end', getHighlightResizeState, rangySelection.isBackwards()) ||
    !rangySelection.rangeCount ||
    rangySelection.isCollapsed
  ) {
    return;
  }

  // If this selection is just one or two words, dont grab punctuation;
  if (countWhitespace(rangySelection.toString()) < 2) {
    return;
  }

  const rangyRange = rangySelection.getRangeAt(rangySelection.rangeCount - 1);
  if (
    isImage(rangyRange.endContainer) ||
    isImage(rangyRange.endContainer.childNodes[rangyRange.endOffset]) ||
    isImage(rangyRange.endContainer.childNodes[rangyRange.endOffset - 1])
  ) {
    return;
  }
  const lastNodeInSelection = rangyRange.endContainer;
  const offsetInNode = rangyRange.endOffset;

  if (
    !lastNodeInSelection ||
    !lastNodeInSelection.textContent ||
    (lastNodeInSelection.parentElement && lastNodeInSelection.parentElement.nodeName === 'RW-HIGHLIGHT')
  ) {
    // return if no text or its already a highlight we are resizing
    return;
  }

  /*
    IF we are at the end of the node
    IF we have a parent element and its an inline tag
  */
  if (
    lastNodeInSelection.textContent.length === offsetInNode &&
    lastNodeInSelection.parentElement &&
    INLINE_TEXT_TAGS.includes(lastNodeInSelection.parentElement.nodeName ?? '')
  ) {
    const { siblings: nextTextNodes } = getSiblings({
      direction: 'next',
      element: lastNodeInSelection.parentElement,
      matcher: isTextNode,
      shouldIncludeNonElements: true,
    });
    /*
     if we have a next text node select the node at the very start of its text and
     If it starts on a punctuation, this code will then grab it recursively
    */
    if (
      nextTextNodes.length > 0 &&
      nextTextNodes[0].textContent &&
      nextTextNodes[0].textContent[0] !== ' '
    ) {
      rangyRange.setEnd(nextTextNodes[0], 1);
      setSingleRangeInRangySelection(rangyRange, rangySelection, 'maintain-current-direction');
      return grabPunctuationAtEnd(rangySelection, getHighlightResizeState);
    }
  }

  let currentOffset = offsetInNode;
  while (currentOffset <= lastNodeInSelection.textContent.length) {
    const charToTest = lastNodeInSelection.textContent.slice(currentOffset, currentOffset + 1);
    if (!PUNCTUATION_REGEX.test(charToTest)) {
      break;
    }
    currentOffset += 1;
  }

  if (currentOffset === offsetInNode) {
    return;
  }

  rangyRange.setEnd(lastNodeInSelection, currentOffset);
  setSingleRangeInRangySelection(rangyRange, rangySelection, 'maintain-current-direction');
}

// NOTE: this mutates the input selection
const trimSelectionStart = (
  rangySelection: RangySelection,
  getHighlightResizeState: () => HighlightResizeState,
): {
  // For debugging
  reasonWhyItDidNotTrim: string | null;
  extra?: { [key: string]: unknown };
} => {
  if (!canModifyEnd('start', getHighlightResizeState, rangySelection.isBackwards())) {
    return {
      reasonWhyItDidNotTrim: 'not allowed to modify',
    };
  }

  if (!rangySelection.rangeCount) {
    return {
      reasonWhyItDidNotTrim: '!rangeCount',
    };
  }

  const rangyRange = rangySelection.getRangeAt(0);
  if (
    isImage(rangyRange.startContainer) ||
    isImage(rangyRange.startContainer.childNodes[rangyRange.startOffset]) ||
    isImage(rangyRange.startContainer.childNodes[rangyRange.startOffset + 1])
  ) {
    return {
      reasonWhyItDidNotTrim: 'isImage checks',
    };
  }

  const selectedText = rangySelection.toString().replace(/\u2060/g, '');
  if (!selectedText || !/^\s/.test(selectedText)) {
    return {
      reasonWhyItDidNotTrim: 'No whitespace in selection.toString()',
    };
  }

  const didContract = contractRangeIfPossible({
    doc: rangySelection.anchorNode?.ownerDocument ?? undefined,
    endToMove: 'start',
    rangyRange,
  });
  if (!didContract) {
    return {
      reasonWhyItDidNotTrim: 'contractRangeIfPossible did nothing',
    };
  }
  const textAfterContraction = rangyRange.toString().replace(/\u2060/g, ''); // Remove Word-Joiner
  if (!textAfterContraction || !textAfterContraction.includes(selectedText.trim())) {
    return {
      extra: {
        rangyRange,
        selectedText,
        textAfterContraction,
      },
      reasonWhyItDidNotTrim:
        'contractRangeIfPossible did run but range.toString() is empty now or no longer contains the original text trimmed (I have no idea why that would be the case)',
    };
  }
  setSingleRangeInRangySelection(rangyRange, rangySelection, 'maintain-current-direction');

  // Safety check; blocks hypotethical infinite loop
  if (rangySelection.toString() !== selectedText) {
    trimSelectionStart(rangySelection, getHighlightResizeState);
  }

  return {
    reasonWhyItDidNotTrim: null,
  };
};

// NOTE: this mutates the input selection
const trimSelectionEnd = (
  rangySelection: RangySelection,
  getHighlightResizeState: () => HighlightResizeState,
): ReturnType<typeof trimSelectionStart> => {
  const result = {
    didUpdateSelection: false,
    wasAllowedToTrim: false,
  };

  if (!canModifyEnd('end', getHighlightResizeState, rangySelection.isBackwards())) {
    return {
      reasonWhyItDidNotTrim: 'not allowed to modify',
    };
  }

  result.wasAllowedToTrim = true;

  const rangyRange = rangySelection.getRangeAt(rangySelection.rangeCount - 1);
  // Don't call this if the next character is whitespace
  if (
    isImage(rangyRange.endContainer) ||
    isImage(rangyRange.endContainer.childNodes[rangyRange.endOffset]) ||
    isImage(rangyRange.endContainer.childNodes[rangyRange.endOffset - 1])
  ) {
    return {
      reasonWhyItDidNotTrim: 'isImage checks',
    };
  }

  const selectedText = rangySelection.toString().replace(/\u2060/g, ''); // Remove Word-Joiner
  if (!selectedText || !/\s$/.test(selectedText)) {
    return {
      reasonWhyItDidNotTrim: 'No whitespace in selection.toString()',
    };
  }

  const didContract = contractRangeIfPossible({
    doc: rangySelection.anchorNode?.ownerDocument ?? undefined,
    endToMove: 'end',
    rangyRange,
  });

  if (!didContract) {
    return {
      reasonWhyItDidNotTrim: 'contractRangeIfPossible did nothing',
    };
  }
  const textAfterContraction = rangyRange.toString().replace(/\u2060/g, ''); // Remove Word-Joiner
  if (!textAfterContraction || !textAfterContraction.includes(selectedText.trim())) {
    return {
      extra: {
        rangyRange,
        selectedText,
        textAfterContraction,
      },
      reasonWhyItDidNotTrim:
        'contractRangeIfPossible did run but range.toString() is empty now or no longer contains the original text trimmed (I have no idea why that would be the case)',
    };
  }
  setSingleRangeInRangySelection(rangyRange, rangySelection, 'maintain-current-direction');

  // Safety check; blocks hypotethical infinite loop
  if (rangySelection.toString() === selectedText) {
    trimSelectionEnd(rangySelection, getHighlightResizeState);
  }

  return {
    reasonWhyItDidNotTrim: null,
  };
};

/*
  NOTE: this mutates the input selection.
  Why is `getHighlightResizeState` a function? Because some of the functions that need to check it are
  recursive and the value could change in the meantine.
*/
export default ({
  getHighlightResizeState,
  selection,
  shouldNotGrabPunctuation,
}: {
  getHighlightResizeState: () => HighlightResizeState;
  selection: RangySelection;
  shouldNotGrabPunctuation?: boolean;
}): {
  end: ReturnType<typeof trimSelectionEnd>;
  start: ReturnType<typeof trimSelectionStart>;
} => {
  const resultOfStartTrim = trimSelectionStart(selection, getHighlightResizeState);
  const resultOfEndTrim = trimSelectionEnd(selection, getHighlightResizeState);

  if (!shouldNotGrabPunctuation) {
    grabPunctuationAtStart(selection, getHighlightResizeState);
    grabPunctuationAtEnd(selection, getHighlightResizeState);
  }

  return {
    end: resultOfEndTrim,
    start: resultOfStartTrim,
  };
};
