// eslint-disable-next-line import/no-cycle,restrict-imports/restrict-imports
import { getScrollingManager } from '../../mobile/contentFrameInternals/getScrollingManager';
import type { TtsPosition, WordBoundary } from '../../types';
import { LenientWindow } from '../../types/LenientWindow';
import { isInReactNativeWebView } from '../../utils/environment';
import type { MaybePromise } from '../../utils/typescriptUtils';
// eslint-disable-next-line import/no-cycle
import { portalGate as portalGateToForeground } from '../portalGates/contentFrame/from/reactNativeWebview';
import getRangyClassApplier from '../utils/getRangyClassApplier';
import getVisibilityDetails from '../utils/getVisibilityDetails';
import { serializePosition } from '../utils/locationSerializer';
import { findWordBoundaryForTrackPosition } from './findWordBoundaryForTrackPosition';
import {
  findTTSableElementFromCenterOfViewport,
  findTtsAbleNode,
  populateTtsAbleElements,
  TextToSpeechContentFrameError,
} from './textToSpeechUtils';
import scrollIntoView from './utils/scrollIntoView';

let lastTtsWord: string | null = null;
let ttsAbleElements: HTMLElement[] = [];
let wordBoundariesForVoice: WordBoundary[] | null = null;

function getIndicatorElements() {
  const start = document.querySelector<HTMLElement>('.tts-position-indicator-start');
  const end = document.querySelector<HTMLElement>('.tts-position-indicator-end');
  if (!start || !end) {
    throw new Error('TTS position indicator elements not found');
  }
  return {
    start,
    end,
  };
}

export async function getFocusableElementIndexFromCurrentScrollPosition({
  contentContainer,
  ttsAbleElements,
  window,
}: {
  contentContainer: HTMLElement;
  ttsAbleElements: HTMLElement[];
  window: LenientWindow;
}): Promise<
  | {
      elementIndex: number;
      position: number;
    }
  | undefined
> {
  const { element, index } = findTTSableElementFromCenterOfViewport(
    contentContainer,
    ttsAbleElements,
    window,
  );
  if (!element) {
    throw new TextToSpeechContentFrameError('cannot play TTS, no TTSable element found from selection');
  }
  let precedingElementsTextLength = 0;
  // Add text length up to the parent node
  for (const ttsAbleElement of ttsAbleElements) {
    if (element === ttsAbleElement) {
      break;
    }
    precedingElementsTextLength += ttsAbleElement.textContent?.length ?? 0;
  }
  // We need to recursively add up all text up to this selection within the node
  const startOffset = precedingElementsTextLength;
  // TTS chars per second is 16.7 on backend
  const position = startOffset / 16.7;
  return {
    elementIndex: index,
    position,
  };
}

export async function getPositionForTtsFromCurrentScrollPosition({
  contentContainer,
  getIsDocumentScrolledToTop,
  ttsAbleElements,
  window,
}: {
  contentContainer: HTMLElement;
  getIsDocumentScrolledToTop(): boolean;
  ttsAbleElements: HTMLElement[];
  window: LenientWindow;
}): Promise<
  | {
      elementIndex?: number;
      position: number;
    }
  | undefined
> {
  if (!contentContainer) {
    throw new TextToSpeechContentFrameError('No contentContainer');
  }
  if (getIsDocumentScrolledToTop()) {
    return { position: 0 };
  }
  if (!wordBoundariesForVoice) {
    // If we have no word boundaries, guess the timestamp
    return getFocusableElementIndexFromCurrentScrollPosition({
      contentContainer,
      ttsAbleElements,
      window,
    });
  }
  const { element } = findTTSableElementFromCenterOfViewport(contentContainer, ttsAbleElements, window);
  if (!element) {
    throw new TextToSpeechContentFrameError(
      'getPositionForTtsFromCurrentScrollPosition: no containingEl',
    );
  }
  // Once we have a containing node, find it inside our list of TTSable nodes
  const nodeIndex = ttsAbleElements.indexOf(element);
  // Get the offset of the selection
  const closestWordBoundary = wordBoundariesForVoice.find((wordBoundary) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_trackPos, _textPos, _word, _paraTextPos, paraIndex] = wordBoundary;
    if (paraIndex < nodeIndex) {
      return false;
    }
    return paraIndex === nodeIndex;
  });
  // This offset might match an existing word boundary
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // this.ttsAutoScrollingEnabled = true;
  // If we failed to match the word, revert to guessing
  if (!closestWordBoundary || closestWordBoundary[4] !== nodeIndex) {
    const result = await getFocusableElementIndexFromCurrentScrollPosition({
      contentContainer,
      ttsAbleElements,
      window,
    });
    window.getSelection()?.removeAllRanges();
    return result;
  }
  window.getSelection()?.removeAllRanges();
  // But we probably didnt fail, so use the word timestamp!
  return { position: closestWordBoundary[0] };
}

export async function getPositionForTtsFromCurrentScrollPositionUsingIds(
  contentContainerId: string,
  scrollableRootId: string,
): ReturnType<typeof getPositionForTtsFromCurrentScrollPosition> {
  const contentContainer = document.getElementById(contentContainerId);
  if (!contentContainer) {
    throw new Error('contentContainer not found');
  }
  const scrollableRoot = document.getElementById(scrollableRootId);
  if (!scrollableRoot) {
    throw new Error('scrollableRoot not found');
  }
  return getPositionForTtsFromCurrentScrollPosition({
    contentContainer,
    getIsDocumentScrolledToTop: () => scrollableRoot.scrollTop === 0,
    ttsAbleElements,
    window,
  });
}

export function getRectsFromTtsPosition({
  contentContainer,
  scrollableRoot,
  ttsPosition,
}: {
  contentContainer: HTMLElement | undefined;
  scrollableRoot: Element;
  ttsPosition: TtsPosition;
}):
  | {
      clientRects: DOMRectList; // to figure out if our word boundary actually spans two lines, if like a word is wrapping
      containerRect: DOMRect; // what we use instead of rect (which could be called wordRect) if perfect word match is false
      perfectWordMatch: boolean; // if we match on word or surrounding container
      rect: DOMRect; // which we can scroll to
      range: Range; // the generated range itself
    }
  | undefined {
  if (!contentContainer) {
    throw new TextToSpeechContentFrameError('Content container not found');
  }

  const { textPos, word: ttsWord, paraTextPos, paraIndex } = ttsPosition;
  // strip new line chars from the word
  const word = ttsWord.replace(/\r?\n|\r/g, '');
  if (word === lastTtsWord) {
    return;
  }
  lastTtsWord = word;
  if (word.length === 1 && word.match(/[!#$%&()*,./:;=?\\^_`{}~-]/g)) {
    // Skip punctuation words
    return;
  }

  if (!ttsAbleElements.length) {
    populateTtsAbleElements(contentContainer, ttsAbleElements);
  }

  let wordOffset = 0;
  let currentNode;
  if (ttsAbleElements[paraIndex]) {
    currentNode = ttsAbleElements[paraIndex];
    wordOffset = paraTextPos;
  }
  if (!currentNode) {
    // Fallback to searching through entire document for the node using text position
    [currentNode, wordOffset] = findTtsAbleNode(textPos, ttsAbleElements);
  }

  if (!currentNode || !currentNode.textContent) {
    throw new Error('No node found');
  }

  // We need to find the exact textNode inside the currentNode

  // New traverse idea
  // Loop through children, adding up their text content until we surpass the wordOffset
  // Once we do that, the previous child will be the right node
  // Get the paragraph length up to that point, and recurse until the right node is a text node
  if (wordOffset >= currentNode.textContent.length) {
    wordOffset = currentNode.textContent.length - 1;
  }
  // If the current node is a LI item inside an OL, add some padding for the number
  if (currentNode.nodeName === 'LI' && currentNode.parentNode?.nodeName === 'OL') {
    wordOffset -= 3;
    if (wordOffset < 0) {
      wordOffset = 0;
    }
  }

  const traverse = (currentNode: Node, startingOffset: number): Node | undefined => {
    if (currentNode.nodeType === 3) {
      // The found text node will be offset from the parent html element by startingOffset amount. This needs to be reflected
      // in the wordOffset we found previously, which was relative to the parent HTML element
      wordOffset -= startingOffset;
      return currentNode;
    }
    // Loop through children, adding up their text content until we surpass the wordOffset
    let textOffset = startingOffset;
    if (currentNode.childNodes.length === 1) {
      return traverse(currentNode.childNodes[0], textOffset);
    }
    for (const child of currentNode.childNodes) {
      const childTextContent = child.textContent;
      if (!childTextContent) {
        continue;
      }
      if (textOffset + childTextContent.length > wordOffset) {
        // we have surpassed the wordOffset, the word should be in this child
        return traverse(child, textOffset);
      }
      textOffset += childTextContent.length;
    }
  };

  // We now should have the exact text content node that contains our word
  const lastSeenTextNode = traverse(currentNode, 0);

  if (!lastSeenTextNode || !lastSeenTextNode.textContent) {
    throw new TextToSpeechContentFrameError('Could not find text node');
  }
  // Using the text node, find the exact range containing the word
  // If we cannot find that, fallback to selecting the entire element
  let perfectWordMatch = true;
  const range = new Range();
  range.setStart(lastSeenTextNode, 0);
  range.collapse(true);
  range.setStart(lastSeenTextNode, wordOffset);
  range.setEnd(
    lastSeenTextNode,
    Math.min(wordOffset + word.length, lastSeenTextNode.textContent.length),
  );

  const clientRects = range.getClientRects();
  // If the word does not match, fallback to the entire row
  if (range.collapsed || !word.startsWith(range.toString()) || clientRects.length > 2) {
    // If the range starts with the word, lets just highlight that part
    range.setStart(lastSeenTextNode, Math.max(0, wordOffset - 50));
    range.setEnd(
      lastSeenTextNode,
      Math.min(lastSeenTextNode.textContent.length, wordOffset + word.length + 50),
    );
    perfectWordMatch = false;
  }

  const rect = perfectWordMatch ? range.getClientRects()[0] : range.getBoundingClientRect();
  const containerRect = lastSeenTextNode.parentElement
    ? lastSeenTextNode.parentElement.getBoundingClientRect()
    : scrollableRoot.getBoundingClientRect();
  if (!rect?.width) {
    return;
  }
  return {
    clientRects,
    containerRect,
    perfectWordMatch,
    range,
    rect,
  };
}

export async function hideWordBoundaryIndicator() {
  const indicatorElements = getIndicatorElements();

  indicatorElements.start.style.top = `${0}px`;
  indicatorElements.start.style.left = `${0}px`;
  indicatorElements.start.style.height = `${0}px`;
  indicatorElements.start.style.width = `${0}px`;
  indicatorElements.start.style.opacity = '0';
  indicatorElements.start.style.transform = `translateX(${0}px)`;

  indicatorElements.end.style.top = `${0}px`;
  indicatorElements.end.style.left = `${0}px`;
  indicatorElements.end.style.height = `${0}px`;
  indicatorElements.end.style.width = `${0}px`;
  indicatorElements.end.style.opacity = '0';
  indicatorElements.end.style.transform = `translateX(${0}px)`;

  setTimeout(() => {
    indicatorElements.start.classList.remove('tts-position-indicator--with-transition');
    indicatorElements.end.classList.remove('tts-position-indicator--with-transition');
  }, 0);
}

export async function onDocumentIdChangedForTts() {
  lastTtsWord = null;
  ttsAbleElements = [];
  wordBoundariesForVoice = null;
}

export async function scrollToTtsPosition({
  contentContainer,
  isAutoScrollEnabled,
  scrollableRoot,
  ttsPosition,
}: {
  contentContainer?: HTMLElement;
  isAutoScrollEnabled: boolean;
  scrollableRoot: HTMLElement;
  ttsPosition: TtsPosition;
}) {
  const mobileScrollingManager = getScrollingManager();

  const rectsResult = getRectsFromTtsPosition({
    contentContainer,
    scrollableRoot,
    ttsPosition,
  });

  if (!rectsResult) {
    return;
  }

  await updateTtsIndicator({
    ...rectsResult,
    contentContainer,
    getScrollableRoot: isInReactNativeWebView
      ? () => mobileScrollingManager.getScrollingElement()
      : () => scrollableRoot,
    getScrollableRootTop: isInReactNativeWebView
      ? () => mobileScrollingManager.getScrollingElementTop()
      : () => scrollableRoot.scrollTop ?? 0,
  });

  if (isAutoScrollEnabled) {
    if (isInReactNativeWebView) {
      mobileScrollingManager.scrollViewportToCurrentTTSLocation(rectsResult.rect);
    } else {
      // Is it in the top 70% of the scrollable root / viewport?
      const { isTopInView } = await getVisibilityDetails({
        canCauseReflow: true,
        rootPercentRange: {
          top: 0,
          bottom: 0.7,
        },
        scrollableRoot,
        subject: rectsResult.rect,
      });

      if (!isTopInView) {
        await scrollIntoView(rectsResult.rect, {
          behavior: 'smooth',
          block: 'start',
          margin: 128, // At least `--sidebar-nav-height` x2 so we don't show an indicator on top of the page header
          scrollableAncestor: scrollableRoot,
        });
      }
    }
  }
}

export async function setTtsWordBoundariesForVoice(value: WordBoundary[] | null = null) {
  wordBoundariesForVoice = value;
}

/*
  Given some new rects from a text selection
  Update the TTS slugs to wrap the text
*/
export async function updateTtsIndicator({
  clientRects,
  containerRect,
  contentContainer,
  getScrollableRoot,
  getScrollableRootTop,
  perfectWordMatch,
  range,
  rect,
}: {
  clientRects: DOMRectList;
  containerRect: DOMRect;
  contentContainer?: HTMLElement;
  getScrollableRoot(): MaybePromise<HTMLElement | null>;
  getScrollableRootTop(): MaybePromise<number>;
  perfectWordMatch: boolean;
  range: Range;
  rect: DOMRect;
}): Promise<number> {
  const ttsIndicatorElements = getIndicatorElements();

  const scrollableRootTop = await getScrollableRootTop();
  const ttsPosTop = Math.floor(scrollableRootTop + rect.top);
  const ttsWidth = perfectWordMatch ? rect.width : containerRect.width;
  const ttsTransformLeft = perfectWordMatch ? rect.left : containerRect.left;
  const ttsHeight = rect.height;

  ttsIndicatorElements.start.style.top = `${ttsPosTop}px`;
  ttsIndicatorElements.start.style.height = `${ttsHeight}px`;
  ttsIndicatorElements.start.style.width = `${ttsWidth}px`;
  ttsIndicatorElements.start.style.opacity = '1';
  ttsIndicatorElements.start.style.transform = `translateX(${ttsTransformLeft}px)`;
  ttsIndicatorElements.start.classList.remove('tts-position-indicator-large');

  if (!perfectWordMatch && clientRects.length !== 2) {
    ttsIndicatorElements.start.classList.add('tts-position-indicator-large');
  }

  if (!perfectWordMatch || clientRects.length !== 2) {
    ttsIndicatorElements.end.style.top = `${ttsPosTop}px`;
    ttsIndicatorElements.end.style.height = `${0}px`;
    ttsIndicatorElements.end.style.width = `${0}px`;
    ttsIndicatorElements.end.style.opacity = '0';
    ttsIndicatorElements.end.style.transform = `translateX(${ttsTransformLeft}px)`;
  } else {
    // If we need to wrap the word around two lines
    const rectEnd = range.getClientRects()[1];
    const ttsPosTopEnd = Math.floor(scrollableRootTop + rectEnd.top);
    const ttsWidthEnd = rectEnd.width;
    const ttsLeftEnd = rectEnd.left;
    const ttsHeightEnd = rectEnd.height;
    ttsIndicatorElements.start.style.top = `${ttsPosTopEnd}px`;
    ttsIndicatorElements.start.style.height = `${ttsHeightEnd}px`;
    ttsIndicatorElements.start.style.width = `${ttsWidthEnd}px`;
    ttsIndicatorElements.start.style.transform = `translateX(${ttsLeftEnd}px)`;
    ttsIndicatorElements.end.style.top = `${ttsPosTop}px`;
    ttsIndicatorElements.end.style.height = `${ttsHeight}px`;
    ttsIndicatorElements.end.style.width = `${ttsWidth}px`;
    ttsIndicatorElements.end.style.opacity = '1';
    ttsIndicatorElements.end.style.transform = `translateX(${ttsTransformLeft}px)`;
  }

  setTimeout(() => {
    ttsIndicatorElements.start.classList.add('tts-position-indicator--with-transition');
    ttsIndicatorElements.end.classList.add('tts-position-indicator--with-transition');
  }, 0);

  const scrollableRoot = await getScrollableRoot();
  let serializedPosition;
  if (contentContainer) {
    serializedPosition = serializePosition({
      classApplier: getRangyClassApplier(),
      node: range.startContainer,
      offset: 0,
      rootNode: contentContainer,
    });
  }
  portalGateToForeground.emit('tts-indicator-moved', {
    currentScrollValue: ttsPosTop,
    maxScrollValue: scrollableRoot?.scrollHeight ?? 0,
    clientScrollableWindowSize: scrollableRoot?.clientHeight ?? 0,
    serializedElementVerticalOffset: (scrollableRoot?.clientHeight ?? 0) / 2,
    serializedPosition,
  });
  return ttsPosTop;
}

export async function updateWordBoundaryIndicator({
  contentContainerId,
  isAutoScrollEnabled,
  position,
  scrollableRootId,
}: {
  contentContainerId?: string;
  isAutoScrollEnabled: boolean;
  position: number;
  scrollableRootId?: string;
}) {
  const nearestTtsPosition = findWordBoundaryForTrackPosition(position, wordBoundariesForVoice ?? []);
  // If the track position is over 2 seconds away from our progress, most likely this is not right
  // Lets be patient and not jump to that spot right away
  if (nearestTtsPosition && Math.abs(nearestTtsPosition.trackPos - position) < 2) {
    let scrollableRoot: HTMLElement;
    let contentContainer: HTMLElement | undefined;
    if (isInReactNativeWebView) {
      const mobileScrollingManager = getScrollingManager();
      scrollableRoot = mobileScrollingManager.getScrollingElement();
      contentContainer = mobileScrollingManager.documentTextContent;
    } else {
      if (!scrollableRootId) {
        throw new Error('!scrollableRootId');
      }
      const scrollableRootElement = document.getElementById(scrollableRootId);
      if (!scrollableRootElement) {
        throw new Error('!scrollableRootElement');
      }
      scrollableRoot = scrollableRootElement;
      contentContainer =
        (contentContainerId && document.getElementById(contentContainerId)) || undefined;
    }

    scrollToTtsPosition({
      contentContainer,
      isAutoScrollEnabled,
      scrollableRoot,
      ttsPosition: nearestTtsPosition,
    });
  } else {
    portalGateToForeground.emit('fetch-word-boundaries');
  }
}
