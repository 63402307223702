import type { DocumentContent, DocumentLinkSet, RSSSuggestion } from '../../types';
import type { ChunkedDocumentContent } from '../../types/chunkedDocuments';
import Store from './Store'; // eslint-disable-line import/no-cycle

export default {
  documentLinks: new Store<DocumentLinkSet, 'parsed_doc_id'>({
    endpointPrefix: 'documents/links',
    name: 'documentLinkSet',
    identifierProperty: 'parsed_doc_id',
  }),
  documentContent: new Store<DocumentContent, 'id'>({
    endpointPrefix: 'documents/content',
    name: 'documentContent',
  }),
  chunkedDocumentContent: new Store<ChunkedDocumentContent, 'id'>({
    endpointPrefix: 'documents/content_v2',
    name: 'chunkedDocumentContent',
  }),
  knownRssFeeds: new Store<RSSSuggestion, 'domain'>({
    couldServerReturnFewerItemsThanRequested: true,
    endpointPrefix: 'known_rss_feeds',
    name: 'knownRssFeeds',
    identifierProperty: 'domain',
  }),
};
