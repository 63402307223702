import { isTextNode } from '../../typeValidators';
import makeLogger from '../../utils/makeLogger';
import type { HighlightElement } from '../types';

const logger = makeLogger(__filename);

export default function getTextNodeFromHighlightElement(element: HighlightElement): Text | null {
  const firstTextNode = Array.from(element.childNodes).find(isTextNode);
  if (!firstTextNode) {
    logger.warn('Highlight has no text node', { element });
    return null;
  }
  return firstTextNode;
}
