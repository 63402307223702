import type { Event } from '@sentry/browser';

import { appCategory, appVersion, channel, commitId, isExtension } from './utils/environment';
import { ReadwiseFetchNetworkConnectivityError, ReadwiseFetchServerError } from './utils/Errors';
import isSentryEventFromExtension from './utils/isSentryEventFromExtension';

export default ({
  currentExtensionId,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: { currentExtensionId?: string } = {}): { [key: string]: any } => {
  return {
    beforeSend(event: Event) {
      if (isExtension !== isSentryEventFromExtension(event, currentExtensionId)) {
        return null;
      }

      const firstValue = event.exception?.values?.[0]?.value;

      if (isExtension) {
        // Ignore the Firefox mobile app
        if (navigator.userAgent.toLowerCase().includes('android')) {
          return null;
        }

        if (
          typeof firstValue === 'string' &&
          (firstValue.includes('No tab with id:') || firstValue.includes('The browser is shutting down'))
        ) {
          return null;
        }
      }

      if (
        typeof firstValue === 'string' &&
        firstValue.includes(
          "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing",
        )
      ) {
        return null;
      }

      const type = event.exception?.values?.[0]?.type;
      if (
        type &&
        [ReadwiseFetchNetworkConnectivityError.name, ReadwiseFetchServerError.name].includes(type)
      ) {
        return null;
      }

      return event;
    },
    environment: channel,
    release: `${appCategory}@${appVersion === 'unknown' ? commitId : appVersion}`,
    // Percentage of transactions captured for performance monitoring
    tracesSampleRate: 0,
  };
};
