import type { RangyRange, RangySelection } from '../types/rangy';
import convertRangeToRangyRange from './convertRangeToRangyRange';

// Assumption range start is before range end
export default function setSingleRangeInRangySelection(
  range: Range | RangyRange,
  rangySelection: RangySelection,
  direction: 'backwards' | 'forwards' | 'maintain-current-direction' = 'forwards',
) {
  const rangyRange = convertRangeToRangyRange(range);
  const directionToUse =
    direction === 'maintain-current-direction'
      ? rangySelection.isBackwards()
        ? 'backwards'
        : 'forwards'
      : direction;

  if (directionToUse === 'forwards') {
    rangySelection.setSingleRange(rangyRange);
    return;
  }

  const anchorNode = rangyRange.endContainer;
  const anchorOffset = rangyRange.endOffset;
  const focusNode = rangyRange.startContainer;
  const focusOffset = rangyRange.startOffset;

  rangySelection.nativeSelection.setBaseAndExtent(anchorNode, anchorOffset, anchorNode, anchorOffset);
  rangySelection.nativeSelection.extend(focusNode, focusOffset);
  rangySelection.refresh();
}
