// / <reference types="@welldone-software/why-did-you-render" />
// eslint-disable-next-line import/no-unresolved
import React from '_react';
import { isDevOrTest } from 'shared/utils/environment';
import makeLogger from 'shared/utils/makeLogger';

const logger = makeLogger('wdyr.js');

if (isDevOrTest) {
  import('@welldone-software/why-did-you-render')
    .then((whyDidYouRender) => {
      whyDidYouRender.default(React, {
        trackAllPureComponents: false,
      });
    })
    .catch((error) => {
      logger.error('Failed to initialize why-did-you-render', error);
    });
}
