import { useCallback, useState } from 'react';

import { globalState } from '../models';
import background from '../portalGates/toBackground';
import { fetchDocumentContent } from '../stateUpdaters/transientStateUpdaters/documentContent';

export async function fetchWordBoundaries() {
  const { tts } = globalState.getState();
  if (!tts) {
    return;
  }
  const { playingDocId } = tts;
  background.pollLatestState(1);
  fetchDocumentContent([playingDocId], true);
}

export function useFetchWordBoundariesThrottled() {
  // This code is in charge of making a network request every 5 seconds for word boundaries if we are missing them
  const [isFetchingWordBoundaries, setIsFetchingWordBoundaries] = useState(false);

  return useCallback(() => {
    if (isFetchingWordBoundaries) {
      return;
    }
    setIsFetchingWordBoundaries(true);
    fetchWordBoundaries();
    setTimeout(() => {
      setIsFetchingWordBoundaries(false);
    }, 5000);
  }, [isFetchingWordBoundaries]);
}
