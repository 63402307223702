import type { DocumentTag } from '../../types/tags';

export const renderTags = (tags: DocumentTag[]) => {
  const tagContainer = document.querySelector<HTMLElement>('.tags-container');
  if (!tagContainer) {
    throw new Error('No .tags-container found');
  }
  // clear all tags
  tagContainer.innerText = '';
  tags.forEach((tag) => {
    const tagNode = document.createElement('span');
    tagNode.classList.add('tag');
    tagNode.innerText = tag.name;
    tagContainer.appendChild(tagNode);
  });
};
