import debounce from 'lodash/debounce';

import type { AnyDocument } from '../../types';
import type { RxDBInstance } from '../../types/database';
import makeLogger from '../../utils/makeLogger';
import { shouldIncludeDocumentInSearch } from '../../utils/shouldIncludeDocumentInSearch';
// eslint-disable-next-line import/no-cycle
import { documentSearchEngine } from '../documentSearchEngine';
import { portalGate as backgroundPortalGate } from '../portalGates/toBackground';

const logger = makeLogger(__filename);

type DocumentChange = {
  doc: AnyDocument;
  type: 'insert' | 'update' | 'remove';
};

const pendingDocumentChanges: DocumentChange[] = [];

async function commitChangesIntoSearchMetadata() {
  const documentBatch = pendingDocumentChanges.splice(0);
  if (documentBatch.length === 0) {
    return;
  }
  logger.time('commitChangesIntoSearchMetadata()');
  const inserts = documentBatch.filter(({ type }) => type === 'insert').map(({ doc }) => doc);
  const updates = documentBatch.filter(({ type }) => type === 'update').map(({ doc }) => doc);
  const removals = documentBatch.filter(({ type }) => type === 'remove').map(({ doc }) => doc.id);
  logger.debug('commitChangesIntoSearchMetadata()', {
    documentBatch,
    inserts,
    updates,
    removals,
  });
  await Promise.all([
    documentSearchEngine.upsertDocuments(inserts, updates),
    documentSearchEngine.removeDocuments(removals),
  ]);
  logger.timeEnd('commitChangesIntoSearchMetadata()');
}

const debouncedCommitChangesIntoSearchMetadata = debounce(commitChangesIntoSearchMetadata, 2000);

function queueDocumentChange(doc: DocumentChange) {
  pendingDocumentChanges.push(doc);
  if (pendingDocumentChanges.length >= 100) {
    commitChangesIntoSearchMetadata();
  } else {
    debouncedCommitChangesIntoSearchMetadata();
  }
}

export function populateMetadataSearchMiddleware(database: RxDBInstance) {
  let persistentStateLoaded = false;
  backgroundPortalGate.on('persistentStateLoadedFromServer', () => {
    logger.debug('Persistent state loaded, consuming document changes');
    persistentStateLoaded = true;
  });
  database.collections.documents.insert$.subscribe((event) => {
    if (!persistentStateLoaded) {
      return;
    }
    const doc = event.documentData;
    if (!shouldIncludeDocumentInSearch(doc)) {
      return;
    }
    queueDocumentChange({ doc, type: 'insert' });
  });
  database.collections.documents.remove$.subscribe((event) => {
    if (!persistentStateLoaded) {
      return;
    }
    const { documentData: doc } = event;
    if (!shouldIncludeDocumentInSearch(doc)) {
      return;
    }
    queueDocumentChange({ doc, type: 'remove' });
  });
  database.collections.documents.update$.subscribe((event) => {
    if (!persistentStateLoaded) {
      return;
    }
    const { documentData: doc, previousDocumentData: prevDoc } = event;
    if (!shouldIncludeDocumentInSearch(doc)) {
      return;
    }
    if (!prevDoc.parsed_doc_id) {
      // Some types of doc saves don't have a parsed_doc_id upon insert, and only obtain it after content is parsed.
      // We need to treat those cases like inserts.
      logger.debug('Previous version did not have parsed doc ID, this one does, treating like insert', {
        doc,
        prevDoc,
      });
      queueDocumentChange({ doc, type: 'insert' });
    } else {
      queueDocumentChange({ doc, type: 'update' });
    }
  });
}
