// eslint-disable-next-line import/no-cycle
import makeLogger from '../../utils/makeLogger';
import {
  generateHeaderImageFilterValues,
  generateImageFilterCSS,
} from '../ReaderWebview/WebviewHTML/styles/headerStyles';
import { MobileContentFrameWindow } from './types';

declare let window: MobileContentFrameWindow;

const logger = makeLogger(__filename);
function rgbToHsl(rParam: number, gParam: number, bParam: number) {
  const r = rParam / 255;
  const g = gParam / 255;
  const b = bParam / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s: number;
  const l = (max + min) / 2;

  if (max === min) {
    h = 0;
    s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h = h / 6;
  }

  return [h, s, l];
}

function rgbToHsv(rParam: number, gParam: number, bParam: number) {
  const r = rParam / 255;
  const g = gParam / 255;
  const b = bParam / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;
  let h;
  // Calculate Hue
  if (delta === 0) {
    h = 0;
  } else if (max === r) {
    h = ((g - b) / delta) % 6;
  } else if (max === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);
  if (h < 0) {
    h += 360;
  }

  // Calculate Saturation
  const s = max === 0 ? 0 : delta / max;

  // Calculate Value
  const v = max;
  return [h, s, v];
}

function createRGBArray(imgData: ImageData) {
  const pixels = imgData.data;
  const pixelArray: [number, number, number][] = [];

  for (let i = 0, offset, r, g, b; i < pixels.length; i += 4) {
    offset = i * 4;
    r = pixels[offset + 0];
    g = pixels[offset + 1];
    b = pixels[offset + 2];
    pixelArray.push([r, g, b]);
  }
  return pixelArray;
}

// Calculate Mean Square Error between two arrays
// essentially how similar are two images
// For our purposes, values < 3000 we will consider as similar
export function calculateMSE(array1: Uint8ClampedArray, array2: Uint8ClampedArray) {
  if (array1.length !== array2.length) {
    throw new Error('Arrays must have the same length');
  }

  const n = array1.length;
  let sumSquaredDifference = 0;

  for (let i = 0; i < n; i++) {
    const diff = array1[i] - array2[i];
    sumSquaredDifference += diff * diff;
  }

  const mse = sumSquaredDifference / n;
  return mse;
}

export function computeHeaderImageColorData() {
  const theme = window.theme;
  const canvas = document.querySelector('#readerCanvas') as HTMLCanvasElement;
  const headerImageContainer = document.querySelector('#header-image-container') as HTMLElement;
  const headerImage = document.querySelector('#header-image') as HTMLImageElement;
  if (!canvas || !headerImage || !headerImageContainer) {
    return;
  }
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return;
  }
  try {
    ctx.drawImage(headerImage, 0, 0, canvas.width, canvas.height);
    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    if (!imgData) {
      return;
    }
    const rgbArray = createRGBArray(imgData);

    // Don't fear, this code is easier to understand than you might think
    // All we are doing is taking RGB data pulled from the canvas data above, converting RGB to HSL and HSV
    // And then we are averaging up each pixel's lightness, saturation and brightness
    const hslArray = [];
    const hsvArray = [];
    for (const [r, g, b] of rgbArray) {
      // failsafe to guard against invalid data or empty pixels
      if (r === undefined || g === undefined || b === undefined || isNaN(r) || isNaN(g) || isNaN(b)) {
        continue;
      }
      const hsv = rgbToHsv(r, g, b);
      const hsl = rgbToHsl(r, g, b);
      hsvArray.push(hsv);
      hslArray.push(hsl);
    }
    if (!rgbArray || !hslArray || !hsvArray) {
      return;
    }

    let totalSaturation = 0;
    let saturationPixelCount = 0;

    let totalBrightness = 0;
    let brightnessPixelCount = 0;

    let totalLightness = 0;
    let lightnessPixelCount = 0;

    for (const [, saturation, lightness] of hslArray) {
      if (lightness !== undefined && !isNaN(lightness)) {
        totalLightness += lightness;
        lightnessPixelCount += 1;
      }
      if (saturation !== undefined && !isNaN(saturation)) {
        totalSaturation += saturation;
        saturationPixelCount += 1;
      }
    }

    for (const [, , brightness] of hsvArray) {
      if (!brightness || brightness === 0 || isNaN(brightness)) {
        continue;
      }
      totalBrightness += brightness;
      brightnessPixelCount += 1;
    }

    const averageLightness = totalLightness / lightnessPixelCount;
    const averageSaturation = totalSaturation / saturationPixelCount;
    const averageBrightness = totalBrightness / brightnessPixelCount;

    if (averageSaturation === 0 || averageBrightness === 0) {
      // This image must be black?
      return;
    }
    // Compute saturation and opacity modifiers from the image data we calculated
    const { saturation: computedSaturation, opacity: computedOpacity } = generateHeaderImageFilterValues(
      theme,
      averageBrightness,
      averageSaturation,
      averageLightness,
    );
    // create the css filter from the computed saturation and opacity values
    headerImageContainer.style.filter = `${generateImageFilterCSS(computedSaturation, computedOpacity)}`;
  } catch (e) {
    logger.debug('Failed to compute header image data');
  }
}

export function doesTextContentHaveImageNearBeginning() {
  const documentRoot = document.querySelector('.document-root');
  const contentRoot = document.querySelector('#document-text-content');
  if (!contentRoot || !documentRoot) {
    throw new Error('No #document-text-content or .document-root found');
  }
  const contentTop = documentRoot.getBoundingClientRect().top;
  // Get the first image tag in the document content
  const images = contentRoot.querySelectorAll('img');
  if (images.length === 0) {
    return false;
  }
  const firstImage = images[0];
  // if its within the viewport, lets consider moving it to the header
  const imageTop = firstImage.getBoundingClientRect().top - contentTop;
  return imageTop < window.innerHeight - 50;
}
