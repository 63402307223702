import { makeRandomSortRules } from '../../../../database/queryHelpers';
import {
  type AnyDocument,
  type BaseDocument,
  type DocumentId,
  type UserEvent,
  Category,
  DocumentLocation,
  FirstClassDocument,
} from '../../../../types';
import { isDocumentThatCanHaveHighlights } from '../../../../typeValidators';
import nowTimestamp from '../../../../utils/dates/nowTimestamp';
// eslint-disable-next-line import/no-cycle
import database from '../../../database';
// eslint-disable-next-line import/no-cycle
import { globalState, updateState } from '../../../models';
import background from '../../../portalGates/toBackground';
import { updateDocumentLocationOfDoc } from '../../../stateUpdateHelpers';
import { createToast } from '../../../toasts.platform';
import type { StateUpdateOptionsWithoutEventName, StateUpdateResult } from '../../../types';
import { getCategoryUrlArgument } from '../../../utils/categoryUrlHelpers';
import fixBadDocumentLocationsValue from '../../../utils/fixBadDocumentLocationsValue';
import fullDocumentDeletedEventName from '../../../utils/fullDocumentDeletedEventName';
import getUIFriendlyNameForDocumentLocation from '../../../utils/getUIFriendlyNameForDocumentLocation';
import { saveNewDocument } from './create';
import { deleteDocumentById } from './remove';
import { updateDocument, updateDocuments } from './update';

export const moveRandomDocsToInbox = async (
  currentStatus: DocumentLocation,
  options: Omit<Parameters<typeof updateDocument>[2], 'eventName'> & {
    beforeStateUpdate?: () => Promise<void>;
  },
): Promise<void> => {
  if (
    !fixBadDocumentLocationsValue(globalState.getState().persistent.settings.documentLocations).includes(
      currentStatus,
    )
  ) {
    return;
  }
  const { userEvent } = await updateDocuments<FirstClassDocument>(
    {
      selector: {
        triage_status: currentStatus,
      },
      sort: makeRandomSortRules(),
      limit: 5,
    },
    (doc) => {
      updateDocumentLocationOfDoc(doc, DocumentLocation.New);
    },
    {
      ...options,
      eventName: 'move-random-docs-to-inbox',
    },
  );
  if (options.userInteraction) {
    createToast({
      content: `Moved random documents to ${DocumentLocation.New}`,
      category: 'success',
      link: `/${[getCategoryUrlArgument(), DocumentLocation.New].filter(Boolean).join('/')}`,
      linkTooltipContent: `Go to ${DocumentLocation.New}`,
      undoableUserEventId: userEvent?.id,
    });
  }
};

export const markDocumentAsFirstOpened = async (docId: BaseDocument['id']): Promise<void> => {
  const now = nowTimestamp();
  const existingDoc = await database.collections.documents.findOne(docId);
  if (existingDoc) {
    await updateDocument<AnyDocument>(
      docId,
      (doc) => {
        doc.firstOpenedAt = now;
        doc.lastOpenedAt = now;
        doc.lastSeenStatusUpdateAt = now;
      },
      {
        eventName: 'document-opened-first-time',
        isUndoable: false,
        userInteraction: 'unknown',
      },
    );
  }
};

export const markDocumentAsOpened = async (
  docId: BaseDocument['id'],
  isUndoable = false,
): Promise<void> => {
  const now = nowTimestamp();
  const existingDoc = await database.collections.documents.findOne(docId);

  if (existingDoc) {
    await updateDocument<AnyDocument>(
      docId,
      (doc) => {
        if (!doc.firstOpenedAt) {
          doc.firstOpenedAt = now;
        }
        doc.lastOpenedAt = now;
        doc.lastSeenStatusUpdateAt = now;
      },
      {
        eventName: 'document-opened',
        isUndoable,
        userInteraction: 'unknown',
      },
    );
  }
};

export const markDocumentAsUnopened = async (
  docId: BaseDocument['id'],
  isUndoable = false,
): Promise<void> => {
  const existingDoc = await database.collections.documents.findOne(docId);
  if (existingDoc) {
    await updateDocument<AnyDocument>(
      docId,
      (doc) => {
        delete doc.firstOpenedAt;
        delete doc.lastOpenedAt;
        doc.lastSeenStatusUpdateAt = nowTimestamp();
      },
      {
        eventName: 'document-unopened',
        isUndoable,
        userInteraction: 'unknown',
      },
    );
  }
};

export const toggleDocumentOpened = async (
  docId: BaseDocument['id'],
  undoable = false,
): Promise<void> => {
  const existingDoc = await database.collections.documents.findOne(docId);
  if (existingDoc) {
    if (existingDoc.firstOpenedAt) {
      await markDocumentAsUnopened(docId, undoable);
    } else {
      await markDocumentAsOpened(docId, undoable);
    }
  }
};

export const bumpDocumentForUser = async (
  docId: AnyDocument['id'],
  options: Omit<
    Parameters<typeof updateDocument>[1],
    'eventName' | 'shouldRemoveChildren' | 'userInteraction'
  > & { userInteraction: string },
): Promise<ReturnType<typeof updateDocument> | undefined> => {
  const now = nowTimestamp();
  const updateResult = await updateDocument<AnyDocument>(
    docId,
    (doc) => {
      doc.last_status_update = now;
    },
    {
      ...options,
      eventName: 'document-bumped',
      isUndoable: false,
      userInteraction: 'unknown',
    },
  );

  createToast({
    content: `Bumped document to top`,
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
  return updateResult;
};

export const deleteDocumentForUser = async (
  docId: AnyDocument['id'],
  options: Omit<
    Parameters<typeof deleteDocumentById>[1],
    'eventName' | 'shouldDeleteChildren' | 'userInteraction'
  > & {
    shouldCreateToast?: boolean; // Defaults to true
    userInteraction: string;
  },
): Promise<ReturnType<typeof updateDocument> | undefined> => {
  const updateResult = await deleteDocumentById(docId, {
    ...options,
    shouldDeleteChildren: true,
    eventName: fullDocumentDeletedEventName,
  });

  // Optional chaining is used to safely access userEvent.id. This is necessary because
  // userEvent might be undefined if the document was already deleted locally (due to syncing).
  const undoableUserEventId = updateResult.userEvent?.id;

  if (options.shouldCreateToast !== false && undoableUserEventId) {
    createToast({
      content: `Deleted document`,
      category: 'success',
      undoableUserEventId,
    });
  }

  return updateResult;
};

export const safeDelete = async (
  docId: AnyDocument['id'],
  options: Parameters<typeof deleteDocumentForUser>[1],
): Promise<ReturnType<typeof updateDocument> | undefined> => {
  const currentDocument = await database.collections.documents.findOne(docId);
  if (currentDocument && isDocumentThatCanHaveHighlights(currentDocument)) {
    const hasHighlights = currentDocument.children.length !== 0;
    if (hasHighlights) {
      await updateState(
        (state) => {
          state.isDeleteDocumentDialogOpen = true;
        },
        { eventName: 'open-delete-document-dialog', isUndoable: false, userInteraction: null },
      );
    } else {
      return deleteDocumentForUser(docId, options);
    }
  }
};

export const saveLinkToReader = async ({
  onButtonClick,
  newDocumentLocation,
  tooltipContent = `Link saved to ${getUIFriendlyNameForDocumentLocation(newDocumentLocation)}`,
  url,
}: {
  onButtonClick: ((docId: string) => void) | undefined;
  newDocumentLocation: DocumentLocation;
  tooltipContent?: string;
  url: string;
}): Promise<DocumentId> => {
  const { id } = await saveNewDocument(
    { source: 'Reader add from import URL', url },
    { userInteraction: 'unknown' },
  );
  background.pollLatestState(20);

  createToast({
    content: tooltipContent,
    category: 'success',
    link: `/${newDocumentLocation}/read/${id}`,
    buttonText: onButtonClick ? 'Open' : undefined,
    onButtonClick: onButtonClick ? () => onButtonClick(id) : undefined,
  });
  return id;
};

export const setIsExtensionActivatedForDocument = (
  documentId: BaseDocument['id'],
  value: boolean,
  options: StateUpdateOptionsWithoutEventName,
) =>
  updateDocument(
    documentId,
    (doc) => {
      if (![Category.Article, Category.Tweet, Category.Video].includes(doc.category)) {
        throw new Error('Unsupported category');
      }
      doc.isExtensionActivated = value;
    },
    { ...options, eventName: 'extension-bar-activation-changed' },
  );

export const setIsExtensionBarMinimizedForDocument = (
  documentId: BaseDocument['id'],
  value: boolean,
  options: StateUpdateOptionsWithoutEventName,
) =>
  updateDocument(
    documentId,
    (doc) => {
      if (![Category.Article, Category.Tweet, Category.Video].includes(doc.category)) {
        throw new Error('Unsupported category');
      }
      doc.isExtensionBarMinimized = value;
    },
    { ...options, eventName: 'extension-bar-minimization-changed' },
  );

export const saveDocumentNote = async (
  docId: AnyDocument['id'],
  notes: BaseDocument['notes'],
  options: StateUpdateOptionsWithoutEventName,
): StateUpdateResult => {
  return updateDocument(
    docId,
    (doc) => {
      doc.notes = notes;
    },
    { ...options, eventName: 'document-note-updated' },
  );
};

export const setPaginatedModeForDoc = async (
  docId: BaseDocument['id'],
  enabled: boolean,
): Promise<void> => {
  await updateDocument(
    docId,
    (doc) => {
      doc.isPaginatedMode = enabled;
    },
    { userInteraction: 'unknown', eventName: 'update-document-pagination-enabled', isUndoable: false },
  );
};
