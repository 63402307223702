import type { RangyRange, RangySelection } from '../types/rangy';
import convertRangeToRangyRange from './convertRangeToRangyRange';

export default function getFirstRangyRangeFromSelectionIfNotEmpty(
  selection: RangySelection | Selection,
): RangyRange | null {
  if (!selection?.rangeCount) {
    return null;
  }

  const range = selection.getRangeAt(0);
  if (!range || range.collapsed) {
    return null;
  }

  return convertRangeToRangyRange(range);
}
