// eslint-disable-next-line restrict-imports/restrict-imports
import foregroundEventEmitter from '../../foreground/eventEmitter';
// eslint-disable-next-line restrict-imports/restrict-imports
import type * as foregroundMethods from '../../foreground/methods';
// eslint-disable-next-line restrict-imports/restrict-imports
import type { SingleProcessEventNamesAndArguments } from '../../foreground/types/events';
import PortalGate, { CallResponsePayload } from '../../PortalGate';
// eslint-disable-next-line import/no-cycle
import * as methods from '../methods';

/*
  This is the portal gate to the foreground. Specifically, when both the background and
  foreground aren't separated into multiple processes. For the messenger in this case,
  we use an event emitter (same one) on both sides.
*/

export const portalGate = new PortalGate<typeof foregroundMethods, SingleProcessEventNamesAndArguments>({
  getGateName: () => 'Background',
  localMethods: methods,
  loggingColor: 'gray',
  loggingPrefix: '🛠',
  portalName: 'background-foreground',
  sendMessage: async ({ message, data }) => {
    foregroundEventEmitter.emit(message, data);
  },
});

foregroundEventEmitter.onAny((event: string | string[], data: CallResponsePayload['data']) => {
  portalGate.handleIncomingMessage({ message: event as string, data });
});

export default portalGate.methods;
