import { rxAlert } from './alert.platform';
// eslint-disable-next-line import/no-cycle
import background from './portalGates/toBackground';

const onDatabaseSchemaConflict = () => {
  const customMessage = `We need to upgrade your local database to make Reader even better. Please hit the button below to apply the update.`;
  rxAlert(customMessage, background.clearAllLocalData);
};

export default onDatabaseSchemaConflict;
