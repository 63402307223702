import React, { ComponentType } from 'react';
import makeLogger from 'shared/utils/makeLogger';

import DynamicImportFailed from '../components/DynamicImportFailed';

const logger = makeLogger(__filename);

function handleDynamicImportError<T>(error: unknown): { default: ComponentType<T> } {
  // silence error
  logger.error('Error during dynamic import', { error });
  return { default: DynamicImportFailed as unknown as ComponentType<T> }; // technically untrue
}

/**
 * Same as React.lazy(), but in the case of a dynamic import error, silences it and returns a fallback component.
 * This exists because the dynamic import errors happen super often, spam up the error channels, and are not severe.
 * Plus we can't do much about them on our end.
 */
export function reactLazy<T>(
  importer: () => Promise<{ default: ComponentType<T> }>,
): ReturnType<typeof React.lazy<ComponentType<T>>> {
  return React.lazy(async () => {
    try {
      return await importer();
    } catch (error: unknown) {
      return handleDynamicImportError(error);
    }
  });
}

export function reactLazyPickExport<T>(
  importer: () => Promise<{ [exportName: string]: ComponentType<T> | unknown }>,
  exportName: string,
): ReturnType<typeof reactLazy<T>> {
  return React.lazy(async () => {
    try {
      const module = await importer();
      const component = module[exportName];
      if (!component) {
        return handleDynamicImportError(new Error(`No such component: ${exportName}`));
      }
      return { default: component as ComponentType<T> };
    } catch (error: unknown) {
      return handleDynamicImportError(error);
    }
  });
}
